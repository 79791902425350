import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";
import Logo from "../assets/images/logo-white.png";
import CardBg from "../assets/images/card-bg.png";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon.svg";
import { ReactComponent as Check } from "../assets/images/check-solid.svg";
import { ReactComponent as Cross } from "../assets/images/xmark-solid.svg";

const SelectCard = ({ selectCard = () => {} }) => {
  const [cards, setCards] = useState([]);
  const [displayCards, setDisplayCards] = useState([]);
  const [edit, setEdit] = useState({});
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    axiosInstance.get(routes.cardList).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setCards(response.data);
        setDisplayCards(response.data);
        if (response.data.length == 1) {
          selectCard(response.data[0].id);
          if (response.data[0]?.card_name) {
            localStorage.setItem("card_name", response.data[0]?.card_name);
          }
        }
      }
    });
  }, []);

  const handleEditClick = (index, value) => {
    setEdit({ [index]: value });
  };

  const handleNameEdit = (index, value) => {
    let editCopy = { ...edit };
    let currentEdit = { ...editCopy[index] };
    currentEdit = value;
    editCopy[index] = currentEdit;
    setEdit(editCopy);
  };

  const handleEditCancel = (index) => {
    let editCopy = { ...edit };
    delete editCopy[index];
    setEdit(editCopy);
  };

  const handleEditSave = (index) => {
    if (loading || !edit[index]) return;
    let editCopy = { ...edit };
    let newName = editCopy[index];
    let cardsCopy = [...cards];
    let currentCard = { ...cardsCopy[index] };
    currentCard.card_name = newName;
    cardsCopy[index] = currentCard;
    setCards(cardsCopy);
    setQuery("");
    setDisplayCards(cardsCopy);
    delete editCopy[index];
    setEdit(editCopy);
    setLoading(true);
    axiosInstance
      .patch(routes.editCard(cards[index]?.id), {
        card_name: newName,
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleQueryChange = (text) => {
    setQuery(text);
    if (text == "") {
      setDisplayCards([...cards]);
      return;
    }
    let filtered = [...cards];
    filtered = filtered.filter((item) =>
      item.card_name.toLowerCase().includes(text.toLowerCase())
    );
    setDisplayCards(filtered);
  };

  return (
    <div className="select-card-page">
      <div className="logo1 m-b-20">
        <img src={Logo} />
      </div>
      <h2 className="select-card-title col-white">My cards</h2>
      <div className="search-area">
        <input
          type="text"
          className="cards-search-field"
          placeholder="search"
          value={query}
          onChange={(e) => handleQueryChange(e.target.value)}
        />
      </div>
      <div className="cards-list">
        {displayCards.map((card, index) => (
          <div
            className="card-item"
            style={{
              marginRight: cards.length > 1 ? "15px" : "0",
              marginLeft: cards.length > 1 ? "15px" : "0",
              marginBottom: "30px",
            }}
            key={index}
          >
            <div
              className="card-img"
              onClick={() => {
                selectCard(card.id);
                if (card?.card_name) {
                  localStorage.setItem("card_name", card?.card_name);
                }
              }}
            >
              <img src={CardBg} alt="" />
            </div>
            <div className="card-name">
              {edit[index] !== undefined ? (
                <input
                  value={edit[index]}
                  onChange={(e) => handleNameEdit(index, e.target.value)}
                  placeholder="card name"
                  className="card-edit-field"
                />
              ) : (
                card?.card_name
              )}{" "}
              {edit[index] == undefined ? (
                <EditIcon
                  className="card-edit-icon"
                  onClick={() => handleEditClick(index, card.card_name)}
                />
              ) : (
                <>
                  <Check
                    className="card-save-icon"
                    onClick={() => handleEditSave(index)}
                  />
                  <Cross
                    className="card-cancel-icon"
                    onClick={() => handleEditCancel(index)}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCard;
