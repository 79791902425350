import React from "react";
import "./styles.css";

const ColorPicker = ({ label, value, onChange, id, onBlur }) => {
  return (
    <div className="color-picker">
      <label htmlFor={id} className="color-picker__label">
        {label}
      </label>
      <div className="d-flex">
        <input
          type="color"
          className="color-picker__picker"
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <input
          type="text"
          className="color-picker__text-input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          placeholder="Enter color code"
        />
      </div>
    </div>
  );
};

export default ColorPicker;

export const isValidColor = (strColor) => {
  return /^#[0-9A-Fa-f]{6}$/g.test(strColor);
};
