import axios from "axios";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../assets/images/logo-white.png";
import { routes } from "../constants";

const Login = ({ handleLogin = () => {} }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [backendErrors, setBackendErrors] = useState("");

  const params = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    setBackendErrors("");
    let body = {};
    body.email = email;
    body.password = password;
    if (params?.id) {
      body.card = params.id;
    }
    if (isFormValid()) {
      axios
        .post(routes.login, body)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            handleLogin({
              name: response.data.name,
              email: response.data.email,
              subscription: response.data.subscription,
              id: response.data.id,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401 &&
            error.response?.data?.detail
          ) {
            setBackendErrors(error.response.data.detail);
          } else if (
            error.response &&
            error.response.status === 400 &&
            error?.response?.data?.card
          ) {
            setBackendErrors(error.response.data.card);
          } else {
            setBackendErrors("Something went wrong");
          }
        });
    } else {
      return;
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!email) {
      errs.email = "Please enter your email.";
    } else {
      delete errs.email;
    }
    if (!password) {
      errs.password = "Please enter your password.";
    } else {
      delete errs.password;
    }

    setErrors(errs);

    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section className="registration-forms-page">
      <div className="logo1">
        <img src={Logo} />
      </div>
      <div className="container custom-container1">
        <div className="sec-head1 m-b-30">
          <h3 className="col-white"> Log in to Link </h3>
          <p className="sec-p1">Log into your own LinkCard Account</p>
        </div>
        {backendErrors && <p className="error-message">{backendErrors}</p>}
        <div className="form-1">
          <form onSubmit={handleSubmit}>
            <div className="form-field1">
              <input
                type="email"
                placeholder="Email Address"
                className={
                  "field-style1" + (errors.email ? " form-field-error" : "")
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-field1">
              <input
                type="password"
                placeholder="Password"
                className={
                  "field-style1" + (errors.password ? " form-field-error" : "")
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <p className="error-message">{errors.password}</p>
              )}
            </div>
            <div className="form-field-submit m-b-20 text-center">
              <Link to="/forgot-password/" className="forgot-pass6">
                {" "}
                Forgot Password?{" "}
              </Link>
            </div>

            <div className="block-element">
              <button className="submit-btn5" type="submit">
                Login
              </button>
            </div>

            <div className="get-card-now block-element text-center m-t-15">
              <p>
                {" "}
                No account?{" "}
                <a
                  href="https://www.linkcardeg.com/"
                  target="_blank"
                  className="col-orange"
                >
                  {" "}
                  Get your card{" "}
                </a>{" "}
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
