import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo-white.png";
import Hamburger from "../assets/images/hamburger.png";
import { ReactComponent as Icon1 } from "../assets/images/user.svg";
import { ReactComponent as Icon2 } from "../assets/images/hastag.svg";
import { ReactComponent as Icon3 } from "../assets/images/browser.svg";
import { ReactComponent as Icon4 } from "../assets/images/form.svg";
import { ReactComponent as Icon6 } from "../assets/images/users.svg";
import { ReactComponent as Icon7 } from "../assets/images/card.svg";
import { ReactComponent as Icon8 } from "../assets/images/qrcode-solid.svg";
import { ReactComponent as Gear } from "../assets/images/gear.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/logout.svg";
import Cross from "../assets/images/cross.png";

const Sidebar = ({ handleLogout = () => {}, card = {}, cardName = "" }) => {
  const [active, setActive] = useState(null);
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname);
  }, [location?.pathname]);

  return (
    <section className="left-sidebar">
      <div className="logo-2">
        <img src={Logo} />
        <div
          className="navbar-handler"
          onClick={() => setNavbarExpanded(!navbarExpanded)}
        >
          <img src={navbarExpanded ? Cross : Hamburger} />
        </div>
      </div>
      <div
        className={
          "sidebar-menu" + (navbarExpanded ? " sidebar-menu--visible" : "")
        }
      >
        {cardName && (
          <p className="side-bar-card">Currently editing: {cardName}</p>
        )}
        <div className="sidebar-menu__tab">
          <h3 className="sidebar-menu__tab-title">Admin Tools</h3>
          <ul>
            <li
              className={active === "/contact-profile/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/contact-profile/">
                <Icon1 className="sidebar-icon sidebar-icon1" /> Contact Profile
                {card?.card_action === "business-card" && (
                  <span> (active) </span>
                )}
              </Link>
            </li>
            <li
              className={active === "/social-network/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/social-network/">
                <Icon2 className="sidebar-icon" /> Social Network
                {card?.card_action === "social-media" && (
                  <span> (active) </span>
                )}
              </Link>
            </li>
            <li
              className={active === "/website/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/website/">
                <Icon3 className="sidebar-icon" /> Website
                {card?.card_action === "website" && <span> (active) </span>}
              </Link>
            </li>
            <li
              className={active === "/leadform/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/leadform/">
                <Icon4 className="sidebar-icon" /> Lead Form
                {card?.card_action === "link-tree" && <span> (active) </span>}
              </Link>
            </li>
            <li
              className={active === "/qr-code/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/qr-code/">
                <Icon8 className="sidebar-icon" /> QR Code
              </Link>
            </li>
            <li
              className={active === "/exchanged-contacts/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/exchanged-contacts/">
                <Icon6 className="sidebar-icon" /> Exchanged Contacts
              </Link>
            </li>
            <li
              className={active === "/select-card/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/select-card/">
                <Icon7 className="sidebar-icon sidebar-icon1" /> My Cards
              </Link>
            </li>
            <li
              className={active === "/settings/" ? "active" : ""}
              onClick={() => setNavbarExpanded(false)}
            >
              <Link to="/settings/">
                <Gear className="sidebar-icon" /> Settings
              </Link>
            </li>
          </ul>
        </div>
        <div className="sidebar-menu__tab">
          <h3 className="sidebar-menu__tab-title">Others</h3>
          <p className="logout-btn" onClick={() => handleLogout()}>
            <LogoutIcon className="sidebar-icon sidebar-logout" /> Logout
          </p>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
