import React, { useState } from "react";
import axios from "axios";
import Logo from "../assets/images/logo-white.png";
import { routes } from "../constants";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [backendErrors, setBackendErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBackendErrors("");
    setSuccess(false);
    if (isFormValid()) {
      setLoading(true);
      axios
        .post(routes.forgotPassword, {
          email,
        })
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setSuccess(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error?.response?.data?.email
          ) {
            setBackendErrors(error.response.data.email[0]);
          } else {
            setBackendErrors("Something went wrong");
          }
        });
    } else {
      return;
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!email) {
      errs.email = "Please enter your email.";
    } else {
      delete errs.email;
    }

    setErrors(errs);

    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section className="registration-forms-page">
      <div className="logo1 m-b-20">
        <img src={Logo} />
      </div>
      <div className="container custom-container1">
        <div className="sec-head1 m-b-30">
          <h3 className="col-white"> Forgot your password? </h3>
          <p className="sec-p1">
            Enter your email below and we'll send you a link to reset your
            password.
          </p>
        </div>

        {success && (
          <p className="forgot-password-success">
            We've sent you an email with a link to reset your password
          </p>
        )}

        {backendErrors && <p className="error-message">{backendErrors}</p>}
        <div className="form-1">
          <form onSubmit={handleSubmit}>
            <div className="form-field1">
              <input
                type="email"
                placeholder="Email Address"
                className={
                  "field-style1" + (errors.email ? " form-field-error" : "")
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>

            <div className="block-element">
              <button
                className="submit-btn5 forgot-password-submit"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Reset Password"}
              </button>
            </div>
          </form>
          <Link to="/login/" className="forgot-password-back">
            <span className="forgot-password-back-icon">&larr;</span> Back To
            Login
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
