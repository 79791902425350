import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";
import Notification from "../components/notification";

const SocialNetwork = ({ card = {}, social = {}, reloadCard = () => {} }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [socialUrl, setSocialUrl] = useState({ url: "", social_media: "" });
  const [socialOptions, setSocialOptions] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get(routes.socialList).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setSocialOptions(response.data);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(social).length !== 0) {
      setSocialUrl({ url: social.url, social_media: social.social_media });
    }
  }, [social]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!socialUrl.url) {
      setError("Please enter URL.");
      return;
    } else {
      setError("");
      if (Object.keys(social).length === 0) {
        axiosInstance
          .post(routes.socialCreate, {
            card: card.id,
            social_media: socialUrl.social_media || socialOptions[0].id,
            url: socialUrl.url,
          })
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              reloadCard(card.id, "/social-network/", false);
              setShowNotification(true);
            }
          })
          .catch((err) => {
            if (err?.response?.status === 400 && err?.response?.data?.url) {
              setError(err.response.data.url[0]);
            } else {
              setError("Something went wrong");
            }
          });
      } else {
        axiosInstance
          .put(`${routes.socialCreate}/${social.id}`, {
            card: card.id,
            social_media: socialUrl.social_media || socialOptions[0].id,
            url: socialUrl.url,
          })
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              reloadCard(card.id, "/social-network/", false);
              setShowNotification(true);
            }
          })
          .catch((err) => {
            if (err?.response?.status === 400 && err?.response?.data?.url) {
              setError(err.response.data.url[0]);
            } else {
              setError("Something went wrong");
            }
          });
      }
    }
  };

  return (
    <section className="page-content">
      <div className="custom-form2 custom-margin2">
        <div className="custom-form2-head text-center">
          <h3 className="col-black"> Social Networks </h3>
        </div>
        <div className="custom-form2-content">
          {!loading && (
            <form onSubmit={handleSubmit}>
              <div className="form-field3">
                <h6 className="col-black"> Enter profile URL </h6>
                {error && <p className="error-message">{error}</p>}
                <input
                  type="text"
                  placeholder="URL"
                  name=""
                  className="field-style4"
                  value={socialUrl.url}
                  onChange={(e) => {
                    let current = { ...socialUrl };
                    current.url = e.target.value;
                    setSocialUrl(current);
                  }}
                />
              </div>
              <div className="form-field3 m-b-40">
                <h6 className="col-black"> Social network </h6>
                <select
                  className="field-style4"
                  value={socialUrl.social_media}
                  onChange={(e) => {
                    let current = { ...socialUrl };
                    current.social_media = e.target.value;
                    setSocialUrl(current);
                  }}
                >
                  {socialOptions.map((item, index) => (
                    <option value={item.id} key={index}>
                      {" "}
                      {item.name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-field3">
                <button className="submit-btn5"> Activate </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Notification
        message={"You've successfully activated social network"}
        show={showNotification}
        setShow={setShowNotification}
      />
    </section>
  );
};

export default SocialNetwork;
