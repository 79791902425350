import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../axiosApi";
import { defaultColors, routes } from "../constants";
import NoPicture from "../assets/images/no-picture.jpg";
import Notification from "../components/notification";
import { ReactComponent as Cross } from "../assets/images/xmark-solid.svg";
import PhoneInput from "react-phone-number-input";
import ColorPicker, { isValidColor } from "../components/colorPicker/index";
import DragDrop from "../components/fileUpload";

const ContactProfile = ({ profile = {}, card = {}, reloadCard = () => {} }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [phones, setPhones] = useState([{ number: "", phone_type: "Main" }]);
  const [emails, setEmails] = useState([""]);
  const [websites, setWebsites] = useState([""]);
  const [officeAddress, setOfficeAddress] = useState("");
  const [gmaps, setGmaps] = useState("");
  const [bio, setBio] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [socialUrl, setSocialUrl] = useState({
    url: "",
    social_media: "",
  });
  const [socialOptions, setSocialOptions] = useState([]);
  const [socials, setSocials] = useState([]);
  const [autoShow, setAutoShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [colors, setColors] = useState(defaultColors);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (Object.keys(profile).length !== 0) {
      let numbers = [];
      let emails = [];
      let websites = [];
      let socialNetworks = [];
      setImage(profile.profile_image || "");
      setName(profile.name || "");
      setJobTitle(profile.job_title || "");
      setOfficeAddress(profile.address || "");
      setAutoShow(profile.is_automatic_show_file || false);
      setGmaps(profile.google_maps || "");
      setBio(profile.bio || "");
      for (let i = 0; i < profile.number.length; i++) {
        numbers.push({
          number: profile.number[i].number,
          phone_type: profile.number[i].phone_type.toLowerCase(),
        });
      }
      for (let i = 0; i < profile.email.length; i++) {
        emails.push(profile.email[i].email);
      }
      for (let i = 0; i < profile.website.length; i++) {
        websites.push(profile.website[i].website);
      }
      for (let i = 0; i < profile.social_network.length; i++) {
        socialNetworks.push({
          url: profile.social_network[i].url,
          img: profile.social_network[i].social_media_url,
          social_media: profile.social_network[i].social_media,
        });
      }
      if (numbers.length > 0) {
        setPhones(numbers);
      }
      if (emails.length > 0) {
        setEmails(emails);
      }
      if (websites.length > 0) {
        setWebsites(websites);
      }
      if (socialNetworks.length > 0) {
        setSocials(socialNetworks);
      }
      setColors({
        backgroundColor:
          profile?.background_color || defaultColors?.backgroundColor,
        cardColor: profile?.text_color || defaultColors?.cardColor,
        buttonColor: profile?.button_color || defaultColors?.buttonColor,
      });
    }
  }, [profile]);

  useEffect(() => {
    axiosInstance.get(routes.socialList).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setSocialOptions(response.data);
        setSocialUrl({
          url: "",
          social_media: response.data[0]?.id,
        });
      }
    });
  }, []);

  const handleSubmit = (activate) => {
    setErrors({});
    if (!errors.image) {
      let errs = {};
      let data = new FormData();

      let nonEmptyPhones = [...phones];
      let nonEmptyEmails = [...emails];
      let nonEmptyWebsites = [...websites];

      nonEmptyPhones = nonEmptyPhones.filter((item) => item.number !== "");
      nonEmptyEmails = nonEmptyEmails.filter((item) => item !== "");
      nonEmptyWebsites = nonEmptyWebsites.filter((item) => item !== "");

      for (let i = 0; i < nonEmptyEmails.length; i++) {
        data.append("emails", nonEmptyEmails[i]);
      }
      for (let i = 0; i < nonEmptyWebsites.length; i++) {
        data.append("websites", nonEmptyWebsites[i]);
      }
      for (let i = 0; i < nonEmptyPhones.length; i++) {
        data.append("numbers", JSON.stringify(nonEmptyPhones[i]));
      }
      for (let i = 0; i < socials.length; i++) {
        data.append("social_networks", JSON.stringify(socials[i]));
      }

      if (imageFile && image) {
        data.append("profile_image", imageFile);
      }
      data.append("name", name);
      data.append("is_automatic_show_file", autoShow);
      data.append("card_id", card?.id);
      data.append("address", officeAddress);
      data.append("bio", bio);
      data.append("job_title", jobTitle);
      data.append("google_maps", gmaps);
      data.append("activate", activate);
      data.append("background_color", colors?.backgroundColor);
      data.append("text_color", colors?.cardColor);
      data.append("button_color", colors?.buttonColor);
      if (logo) {
        data.append("logo", logo);
      }
      let windowReference;
      if (!activate) {
        windowReference = window.open("about:blank", "_blank");
      }
      axiosInstance
        .post(routes.createProfile, data)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            reloadCard(card.id, "/contact-profile/", false);
            if (activate) {
              setShowNotification(true);
            } else {
              windowReference.location = "/preview-profile/" + card.id;
            }
          }
        })
        .catch((error) => {
          if (!activate) {
            windowReference.close();
          }
          if (error.response && error.response.status === 400) {
            let allErrors = error.response.data;
            for (const item in allErrors) {
              if (item === "websites") {
                errs.websites =
                  websites.length > 1
                    ? "Make sure all URLs are valid"
                    : "Please enter a valid URL";
              } else if (item === "emails") {
                errs.emails =
                  emails.length > 1
                    ? "Make sure all emails are valid"
                    : "Please enter a valid email";
              } else if (item === "google_maps") {
                errs.gmaps = "Please enter a valid URL";
              } else if (item === "name") {
                errs.name = "Please enter your name";
              }
            }
          }
          setErrors(errs);
        });
    }
  };

  const handleImageUpload = (event) => {
    let errs = { ...errors };
    var reader = new FileReader();
    if (event?.target?.files[0]?.size > 2097152) {
      errs.image = "Image size is too big (Maximum 2MB)";
    } else {
      delete errs.image;
      if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          setImage(e.target.result);
        };
      }
    }
    setImageFile(event.target.files[0]);
    setErrors(errs);
    event.target.value = "";
  };

  const handlePhoneChange = (value, field, index) => {
    let all = [...phones];
    let current = { ...all[index] };
    current[field] = value;
    all[index] = current;
    setPhones(all);
  };

  const handleRemovePhone = (index) => {
    let all = [...phones];
    all.splice(index, 1);
    setPhones(all);
  };

  const handleEmailChange = (value, index) => {
    let all = [...emails];
    all[index] = value;
    setEmails(all);
  };

  const handleRemoveEmail = (index) => {
    let all = [...emails];
    all.splice(index, 1);
    setEmails(all);
  };

  const handleWebsiteChange = (value, index) => {
    let all = [...websites];
    all[index] = value;
    setWebsites(all);
  };

  const handleRemoveWebsite = (index) => {
    let all = [...websites];
    all.splice(index, 1);
    setWebsites(all);
  };

  const handleAddSocial = () => {
    let all = [...socials];
    let option = socialOptions.filter(
      (item) => item.id == socialUrl.social_media
    );
    if (option.length === 0) {
      return;
    }

    all.push({ ...socialUrl, img: option[0].img });
    setSocials(all);
    setSocialUrl({ url: "", social_media: "" });
    setShowModal(false);
  };

  const handleDeleteSocial = (index) => {
    let all = [...socials];
    all.splice(index, 1);
    setSocials(all);
  };

  const handleRemoveImage = () => {
    let tempImage = image;
    let tempFile = imageFile;
    setImage("");
    setImageFile("");
    axiosInstance.get(routes.deleteContactProfileImage(card.id)).catch(() => {
      setImage(tempImage);
      setImageFile(tempFile);
    });
  };

  return (
    <section className="page-content contact-profile-page">
      <div className="page-header custom-margin1">
        <h3 className="col-black"> Tell us About yourself </h3>
      </div>
      {errors.image && <p className="error-message">{errors.image}</p>}
      {errors.name && <p className="error-message">{errors.name}</p>}

      <form onSubmit={(e) => e.preventDefault()}>
        <div className="bottom-head">
          <div className="col-6">
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                />

                {image ? (
                  <div
                    className="remove-image"
                    onClick={() => handleRemoveImage()}
                  >
                    <Cross className="remove-image-icon" />
                  </div>
                ) : (
                  <label htmlFor="imageUpload"></label>
                )}
              </div>
              <div className="avatar-preview">
                <div
                  id="imagePreview"
                  style={{
                    backgroundImage: image
                      ? `url(${image})`
                      : `url(${NoPicture})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-6 contact-prof-title">
            <textarea
              className="field-style5"
              placeholder="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></textarea>
            <textarea
              className="field-style7"
              placeholder="Your job title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="about-form">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
              <div className="more-fields dual-field">
                {phones.map((phone, index) => (
                  <div className="form-field2 list-field" key={index}>
                    <PhoneInput
                      placeholder="Phone Number"
                      className="field-style2"
                      name=""
                      value={phone.number}
                      international={true}
                      onChange={(value) =>
                        handlePhoneChange(value, "number", index)
                      }
                    />
                    <select
                      value={phone.phone_type}
                      onChange={(e) =>
                        handlePhoneChange(e.target.value, "phone_type", index)
                      }
                      className="field-style2"
                    >
                      <option value="main"> Main </option>
                      <option value="home"> Home </option>
                      <option value="work"> Work </option>
                      <option value="cell"> Cell </option>
                    </select>
                    {index !== 0 && (
                      <span
                        className=" remove-field"
                        onClick={() => handleRemovePhone(index)}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    )}
                  </div>
                ))}
                <div className="more-button">
                  <button
                    type="button"
                    id="phone-btn1"
                    className="more-btn1"
                    onClick={() => {
                      let all = [...phones];
                      all.push({ number: "", phone_type: "Main" });
                      setPhones(all);
                    }}
                  >
                    + Add Phone Number
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
              {errors.emails && (
                <p className="error-message">{errors.emails}</p>
              )}
              <div className="more-fields">
                {emails.map((email, index) => (
                  <div className="form-field2 list-field" key={index}>
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="field-style2"
                      name=""
                      value={email}
                      onChange={(e) => handleEmailChange(e.target.value, index)}
                    />
                    {index !== 0 && (
                      <span
                        className=" remove-field"
                        onClick={() => handleRemoveEmail(index)}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    )}
                  </div>
                ))}
                <div className="more-button">
                  <button
                    type="button"
                    id="email-btn1"
                    className="more-btn1"
                    onClick={() => {
                      let all = [...emails];
                      all.push("");
                      setEmails(all);
                    }}
                  >
                    + Add Email
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
              {errors.websites && (
                <p className="error-message">{errors.websites}</p>
              )}
              <div className="more-fields">
                {websites.map((website, index) => (
                  <div className="form-field2 list-field" key={index}>
                    <input
                      type="text"
                      placeholder="Website"
                      className="field-style2"
                      name=""
                      value={website}
                      onChange={(e) =>
                        handleWebsiteChange(e.target.value, index)
                      }
                    />
                    {index !== 0 && (
                      <span
                        className=" remove-field"
                        onClick={() => handleRemoveWebsite(index)}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    )}
                  </div>
                ))}
                <div className="more-button">
                  <button
                    type="button"
                    id="web-btn1"
                    className="more-btn1"
                    onClick={() => {
                      let all = [...websites];
                      all.push("");
                      setWebsites(all);
                    }}
                  >
                    + Add Website
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6 col-12">
              <div className="form-field2 m-b-30">
                <input
                  type="text"
                  placeholder="Office Address"
                  className="field-style2"
                  name=""
                  value={officeAddress}
                  onChange={(e) => setOfficeAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6 col-12">
              {errors.gmaps && <p className="error-message">{errors.gmaps}</p>}
              <div className="form-field2 m-b-30">
                <input
                  type="text"
                  placeholder="Googlemaps URL"
                  className="field-style2"
                  name=""
                  value={gmaps}
                  onChange={(e) => setGmaps(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6 col-12">
              <div className="form-field2 m-b-20">
                <input
                  type="text"
                  placeholder="Bio"
                  className="field-style2"
                  name=""
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>
            </div>
            {socialOptions.length !== 0 && (
              <div className="col-md-12 col-lg-12 col-sm-6 col-12 block-element">
                <div className="social-adder">
                  <div className="social-adder-head">
                    <h3> Social network </h3>
                  </div>
                  <div className="social-adder-blocks" id="links_tray">
                    {socials.map((social, index) => (
                      <div className="social-icon-block" key={index}>
                        <p
                          className="close-btn4 deleteItem"
                          onClick={() => handleDeleteSocial(index)}
                        >
                          <i className="fa fa-times"> </i>
                        </p>

                        <a
                          href={social.url}
                          className="social-icon6"
                          target="_blank"
                        >
                          <img src={social.img} />
                        </a>
                      </div>
                    ))}
                    <div
                      className="social-add-block"
                      onClick={() => setShowModal(true)}
                    >
                      <p>+</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12 col-lg-12 col-sm-6 col-12 block-element">
              <div>
                <h3 className="contact-profile-appearance-header">
                  {" "}
                  Appearance{" "}
                </h3>
              </div>
              <div className="d-flex mb-3 flex-column flex-lg-row">
                <div className="colors me-4 flex-grow-1 flex-shrink-0">
                  <div className="mb-3">
                    <ColorPicker
                      id={"backgroundColor"}
                      label={"Background Color"}
                      value={colors?.backgroundColor || ""}
                      onChange={(value) => {
                        setColors((prev) => ({
                          ...prev,
                          backgroundColor: value,
                        }));
                      }}
                      onBlur={(value) => {
                        if (!value || !isValidColor(value)) {
                          setColors((prev) => ({
                            ...prev,
                            backgroundColor: defaultColors?.backgroundColor,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <ColorPicker
                      id={"cardColor"}
                      label={"Card Color"}
                      value={colors?.cardColor || ""}
                      onChange={(value) => {
                        setColors((prev) => ({
                          ...prev,
                          cardColor: value,
                        }));
                      }}
                      onBlur={(value) => {
                        if (!value || !isValidColor(value)) {
                          setColors((prev) => ({
                            ...prev,
                            cardColor: defaultColors?.cardColor,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div>
                    <ColorPicker
                      id={"buttonColor"}
                      label={"Button Color"}
                      value={colors?.buttonColor || ""}
                      onChange={(value) => {
                        setColors((prev) => ({
                          ...prev,
                          buttonColor: value,
                        }));
                      }}
                      onBlur={(value) => {
                        if (!value || !isValidColor(value)) {
                          setColors((prev) => ({
                            ...prev,
                            buttonColor: defaultColors?.buttonColor,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="logo-upload ms-4 flex-grow-1 flex-shrink-0">
                  <DragDrop
                    value={logo || profile?.logo}
                    label="Logo (Svg/Vector)"
                    onChange={(value) => setLogo(value)}
                    id="logo-upload"
                    fileTypes={["SVG"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-12 m-b-20 block-element">
              <div className="inline-field1">
                <label>
                  {" "}
                  <input
                    type="checkbox"
                    name=""
                    checked={autoShow}
                    value={autoShow}
                    onChange={(e) => setAutoShow(e.target.checked)}
                  />{" "}
                  Automatically show contact file{" "}
                </label>
              </div>
              <br />
              <div className="form-field-submit2">
                <button
                  className="submit-btn3"
                  type="submit"
                  onClick={() => handleSubmit(true)}
                >
                  {" "}
                  Activate{" "}
                </button>
                <button
                  className="submit-btn4"
                  type="submit"
                  onClick={() => handleSubmit(false)}
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="social-popup1 modal"
        animation={false}
      >
        <button
          type="button"
          className="close-btn2"
          onClick={() => setShowModal(false)}
        >
          <i className="fa fa-times"> </i>
        </button>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="col-md-12 col-lg-12 col-sm-12 col-12">
            <div className="more-fields dual-field">
              <div className="form-field2">
                <input
                  type="text"
                  placeholder="Social Media URL"
                  id="linkField"
                  className="field-style2"
                  name=""
                  value={socialUrl.url}
                  onChange={(e) => {
                    let current = { ...socialUrl };
                    current.url = e.target.value;
                    setSocialUrl(current);
                  }}
                />
                <select
                  className="field-style2"
                  id="socialField"
                  value={socialUrl.social_media}
                  onChange={(e) => {
                    let current = { ...socialUrl };
                    current.social_media = e.target.value;
                    setSocialUrl(current);
                  }}
                >
                  {socialOptions.map((item, index) => (
                    <option key={index} value={item.id} name={item.name}>
                      {" "}
                      {item.name}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="social-submit">
                <button
                  type="button"
                  id="social-btn1"
                  className="add-social-btn"
                  onClick={() => {
                    if (socialUrl.url && socialUrl.social_media) {
                      handleAddSocial();
                    }
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Notification
        message={"You've successfully activated contact profile"}
        show={showNotification}
        setShow={setShowNotification}
      />
    </section>
  );
};

export default ContactProfile;
