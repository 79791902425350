import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/fontawesome-kit-5/css/all.css";
import "./styles/fontawesome-kit-5/css/fontawesome.css";
import "./styles/fontawesome-kit-5/css/brands.css";
import "./styles/fontawesome-kit-5/css/solid.css";
import "./styles/fontawesome-kit-5/js/all.js";
import "./styles/fontawesome-kit-5/js/brands.js";
import "./styles/fontawesome-kit-5/js/solid.js";
import "./styles/fontawesome-kit-5/js/fontawesome.js";
import "animate.css";
import "react-phone-number-input/style.css";
import "./styles/custom.css";
import Register from "./pages/register";
import Login from "./pages/login";
import Sidebar from "./components/sidebar";
import SocialNetwork from "./pages/socialNetwork";
import Website from "./pages/website";
import LeadForm from "./pages/leadFormActivate";
import SavedContacts from "./pages/savedContacts";
import ChooseAction from "./pages/chooseAction";
import PreviewProfile from "./pages/previewProfile";
import ContactProfile from "./pages/contactProfile";
import SelectCard from "./pages/selectCard";
import axiosInstance from "./axiosApi";
import { routes } from "./constants";
import LeadFormPage from "./pages/leadForm";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import MyQR from "./pages/myQr";
import Welcome from "./pages/welcome";
import Plans from "./pages/plans";
import Settings from "./pages/settings";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentCard, setCurrentCard] = useState({});
  const [cardName, setCardName] = useState(
    localStorage.getItem("card_name") || ""
  );
  const [showSideBar, setShowSideBar] = useState(false);
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    axiosInstance.post(routes.logout, {
      refresh: localStorage.getItem("refresh_token"),
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("card");
    localStorage.removeItem("card_name");
    setIsLoggedIn(false);
    window.location.reload();
  };

  const handleLogin = (user) => {
    if (localStorage.getItem("card")) {
      localStorage.removeItem("card");
      localStorage.removeItem("card_name");
    }
    setIsLoggedIn(true);
    setUser(user);
    navigate("/select-card/", { replace: true });
  };

  useEffect(() => {
    setCardName(localStorage.getItem("card_name") || "");
  }, [currentCard]);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setUserLoading(true);
      setIsLoggedIn(true);
      axiosInstance
        .get(routes.me)
        .then((res) => {
          if (res.status && res.status >= 200 && res.status < 300) {
            setUser(res?.data);
            setUserLoading(false);
          }
        })
        .catch(() => setUserLoading(false));
      if (localStorage.getItem("card")) {
        selectCard(localStorage.getItem("card"), location.pathname);
      } else {
        if (
          !location.pathname.includes("/preview-profile") &&
          !location.pathname.includes("/lead-form") &&
          !location.pathname.includes("/reset-password")
        ) {
          navigate("/select-card/");
        }
      }
    } else {
      setIsLoggedIn(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      !location.pathname.includes("/select-card") &&
      !location.pathname.includes("/login") &&
      !location.pathname.includes("/register") &&
      !location.pathname.includes("/preview-profile") &&
      !location.pathname.includes("/lead-form") &&
      !location.pathname.includes("/forgot-password") &&
      !location.pathname.includes("/reset-password") &&
      !location.pathname.includes("/welcome") &&
      !location.pathname.includes("/upgrade-to-pro")
    ) {
      setShowSideBar(true);
    } else {
      setShowSideBar(false);
    }
  }, [location.pathname, isLoggedIn]);

  const selectCard = (id, next = null, shouldLoad = true) => {
    let redirect = "";
    if (shouldLoad) {
      setLoading(true);
    }
    axiosInstance
      .get(routes.cardDetails(id))
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCurrentCard(response.data);
          localStorage.setItem("card", response.data.id);
          if (response.data.card_action === "not-set") {
            redirect = "/choose-action/";
          } else if (response.data.card_action === "business-card") {
            redirect = "/contact-profile/";
          } else if (response.data.card_action === "social-media") {
            redirect = "/social-network/";
          } else if (response.data.card_action === "website") {
            redirect = "/website/";
          } else if (response.data.card_action === "link-tree") {
            redirect = "/leadform/";
          }
          if (next && next !== "/") {
            navigate(next);
          } else {
            navigate(redirect);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        navigate("/select-card/");
      });
  };

  const handleRegister = (user) => {
    if (localStorage.getItem("card")) {
      localStorage.removeItem("card");
      localStorage.removeItem("card_name");
    }
    setUser(user);
    setIsLoggedIn(true);
    navigate("/welcome/");
  };

  return (
    <div className="App">
      {!loading && (
        <React.Fragment>
          {showSideBar && (
            <Sidebar
              handleLogout={handleLogout}
              card={currentCard}
              cardName={cardName}
            />
          )}
          <Routes>
            <Route
              exact
              path="/login/:id"
              element={<Login handleLogin={handleLogin} />}
            />
            <Route
              exact
              path="/login/"
              element={<Login handleLogin={handleLogin} />}
            />
            <Route
              exact
              path="/preview-profile/:id"
              element={<PreviewProfile />}
            />
            <Route
              exact
              path="/forgot-password/"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/reset-password/:token"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/register/:id"
              element={<Register handleRegister={handleRegister} />}
            />
            <Route exact path="/lead-form/:id" element={<LeadFormPage />} />
            {isLoggedIn ? (
              <React.Fragment>
                <Route
                  exact
                  path="/select-card/"
                  element={<SelectCard selectCard={selectCard} />}
                />
                <Route
                  exact
                  path="/social-network/"
                  element={
                    <SocialNetwork
                      card={currentCard}
                      social={currentCard?.social_network || {}}
                      reloadCard={selectCard}
                    />
                  }
                />
                <Route
                  exact
                  path="/website/"
                  element={
                    <Website
                      card={currentCard}
                      website={currentCard?.website || {}}
                      reloadCard={selectCard}
                    />
                  }
                />
                <Route
                  exact
                  path="/leadform/"
                  element={
                    <LeadForm card={currentCard} reloadCard={selectCard} />
                  }
                />
                <Route
                  exact
                  path="/exchanged-contacts/"
                  element={<SavedContacts card={currentCard} />}
                />
                <Route
                  exact
                  path="/contact-profile/"
                  element={
                    <ContactProfile
                      profile={currentCard?.contact_profile || {}}
                      card={currentCard}
                      reloadCard={selectCard}
                    />
                  }
                />
                <Route
                  exact
                  path="/choose-action/"
                  element={<ChooseAction />}
                />
                <Route
                  exact
                  path="/qr-code/"
                  element={<MyQR card={currentCard} />}
                />
                <Route
                  exact
                  path="/welcome/"
                  element={<Welcome user={user} userLoading={userLoading} />}
                />
                {/* <Route exact path="/upgrade-to-pro/" element={<Plans />} /> */}
                <Route exact path="/settings/" element={<Settings />} />
                <Route path="*" element={<Navigate to="/select-card/" />} />
              </React.Fragment>
            ) : (
              <Route path="*" element={<Navigate to="/login/" />} />
            )}
          </Routes>
        </React.Fragment>
      )}
    </div>
  );
};

export default App;
