import React from "react";
import Icon1 from "../assets/images/card-icon1.png";
import Icon2 from "../assets/images/card-icon2.png";
import Icon3 from "../assets/images/card-icon3.jpg";
import Icon4 from "../assets/images/card-icon4.png";

import { Link } from "react-router-dom";

const ChooseAction = () => {
  return (
    <section className="page-content">
      <div className="action-content">
        <div className="action-head">
          <h3 className="col-black">
            {" "}
            What would you like your{" "}
            <span className="col-orange"> Link Card </span> to do?{" "}
          </h3>
        </div>
        <div className="action-options">
          <Link to="/contact-profile/">
            {" "}
            <img src={Icon1} /> <b> Contact Profile </b>
            <span>
              Tap your Link card on a phone and your contact details appear
              instantly.
            </span>{" "}
          </Link>
          <Link to="/website/">
            {" "}
            <img src={Icon2} /> <b> Website </b>
            <span>
              Share business presentations, sign-up forms, product
              demonstrations, and more.
            </span>{" "}
          </Link>
          <Link to="/leadform/">
            {" "}
            <img src={Icon3} /> <b> Lead Form </b>
            <span>
              Exchange contact details seamlessly, follow-up faster and close
              more deals.
            </span>{" "}
          </Link>
          <Link to="/social-network/">
            {" "}
            <img src={Icon4} /> <b> Social Network </b>{" "}
            <span>
              Share links to 10+ social profile channels for streamlined social
              connectivity.
            </span>{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ChooseAction;
