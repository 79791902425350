import axios from "axios";
import { routes } from "./constants";

const axiosInstance = axios.create({
  baseURL: routes.base,
  timeout: 20000,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      const last_refresh_time = localStorage.getItem("last_refresh_time");
      var now = Date.now();
      if (now - last_refresh_time > 7000) {
        originalRequest._retry = true;
        localStorage.setItem("last_refresh_time", now);
        const refresh_token = localStorage.getItem("refresh_token");
        if (!refresh_token) {
          window.location.replace("/login/");
        }
        try {
          const res = await axios.post(
            routes.refresh,
            { refresh: refresh_token },
            {
              timeout: 20000,
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
              },
            }
          );
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + res.data.access;
          originalRequest.headers["Authorization"] = "JWT " + res.data.access;
          return axiosInstance(originalRequest);
        } catch (_error) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("card");
          localStorage.removeItem("card_name");
          window.location.replace("/login/");
        }
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
