import React from "react";
import * as qs from "qs";

const MyQR = ({ card = {} }) => {
  const cardUrl = `https://linkcardeg.me/${card?.id}`;

  const handleDownloadQr = async () => {
    const url = card?.qr_codes;
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const extension = qs.parse(card?.qr_codes, {
        ignoreQueryPrefix: true,
      })?.image_format;
      a.download = `qrCode.${extension}`;
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {}
  };

  const handleCopy = () => {
    navigator?.clipboard?.writeText(cardUrl);
  };

  return (
    <section className="page-content">
      <div className="custom-form2 custom-margin2 qr-page">
        <div className="custom-form2-head text-center">
          <h3 className="col-black"> QR Code </h3>
        </div>
        <div className="custom-form2-content">
          <img src={card?.qr_codes} alt="" className="qr-image" />
          <div className="d-flex flex-column align-items-center">
            {card?.qr_codes && (
              <React.Fragment>
                <button className="submit-btn5" onClick={handleDownloadQr}>
                  Save QR
                </button>
                <p
                  className="pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={handleCopy}
                >
                  {cardUrl} <i className="far fa-copy"></i>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyQR;
