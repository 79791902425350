import React, { useState } from "react";
import Image from "../assets/images/EXCHANGE.JPG";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";
import Notification from "../components/notification";
import { ReactComponent as Cross } from "../assets/images/xmark-solid.svg";

const LeadForm = ({ card = {}, reloadCard = () => {} }) => {
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [fields, setFields] = useState([
    { label: "Full Name", type: "text", isRequired: true },
    { label: "Email", type: "text", isRequired: true },
    { label: "Company", type: "text", isRequired: false },
    { label: "Phone Number", type: "text", isRequired: true },
  ]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [focused, setFocused] = useState(-1);

  const fieldTypes = [{ label: "Text", value: "text" }];

  const activateLeadForm = () => {
    /* setError("");
    if (fields.length === 0) {
      setError("Add at least 1 field");
      return;
    }
    let isFormValid = validate();
    if (
      Object.keys(fieldErrors).length === 0 &&
      fields.length > 0 &&
      isFormValid
    ) { */
    axiosInstance
      .post(routes.activateLeadForm, {
        card: card.id,
      })
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          reloadCard(card.id, "/leadform/", false);
          setShowNotification(true);
        }
      })
      .catch(() => {
        setError("Something went wrong!");
      });
    /*   } */
  };

  const handleFieldChange = (index, value, property) => {
    let fieldsCopy = [...fields];
    let current = { ...fieldsCopy[index] };
    current[property] = value;
    fieldsCopy[index] = current;
    validate(index, value);
    setFields(fieldsCopy);
  };

  const handleRemoveField = (index) => {
    let fieldsCopy = [...fields];
    fieldsCopy.splice(index, 1);
    let errs = { ...fieldErrors };
    delete errs[index];
    setFieldErrors(errs);
    setFields(fieldsCopy);
  };

  const handleAddField = () => {
    let fieldsCopy = [...fields];
    fieldsCopy.push({ label: "", isRequired: true, type: "text" });
    setFields(fieldsCopy);
  };

  const validate = (index = null, value = null) => {
    let errs = { ...fieldErrors };
    if (index === null && value === null) {
      let fieldsCopy = [...fields];
      console.log(fieldsCopy);
      for (let i = 0; i < fieldsCopy.length; i++) {
        if (fieldsCopy[i].label.trim().length === 0) {
          errs[i] = "Please add a label";
        }
      }
      setFieldErrors(errs);
      if (Object.keys(errs).length !== 0) {
        return false;
      } else {
        return true;
      }
    } else {
      value += "";
      if (value.trim().length === 0) {
        errs[index] = "Please add a label";
      } else {
        delete errs[index];
      }
      setFieldErrors(errs);
    }
  };

  return (
    <section className="page-content">
      <div className="custom-form2 custom-margin2 lead-form-activate-page">
        <div className="custom-form2-head text-center">
          <h3 className="col-black"> Lead Form </h3>
        </div>
        <p>
          The lead generation form is is meant for capturing emails and other
          information of potential customers. This is a preview of how it will
          be displayed.
        </p>
        {error && <p className="error-message">{error}</p>}
        <div className="custom-form2-content">
          <img src={Image} alt="" className="lead-form-image" />
          {/* <div className="custom-form2-content field-rows">
            {fields.map((field, index) => (
              <div className="field-row" key={index}>
                <div
                  className={
                    "field-box" +
                    (fieldErrors[index] ? " field-error" : "") +
                    (focused === index ? " focus-in" : "")
                  }
                >
                  <div className="field-label">
                    <label
                      htmlFor="name"
                      style={{
                        top: focused === index || field?.label ? "0" : "27px",
                      }}
                    >
                      Label
                    </label>
                  </div>
                  <div className="field-name">
                    <input
                      type="text"
                      className="form-field"
                      name="label"
                      value={field?.label || ""}
                      onChange={(e) =>
                        handleFieldChange(index, e.target.value, e.target.name)
                      }
                      onFocus={() => setFocused(index)}
                      onBlur={() => setFocused(-1)}
                    />
                    {fieldErrors[index] && (
                      <p className="error">{fieldErrors[index]}</p>
                    )}
                  </div>
                </div>
                <select
                  className="field-type-selector"
                  value={field?.type}
                  name="type"
                  onChange={(e) =>
                    handleFieldChange(index, e.target.value, e.target.name)
                  }
                >
                  {fieldTypes.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <label className="field-checkbox">
                  Required?{" "}
                  <input
                    type="checkbox"
                    checked={field?.isRequired}
                    value={field?.isRequired}
                    name="isRequired"
                    onChange={(e) =>
                      handleFieldChange(index, e.target.checked, e.target.name)
                    }
                  />
                </label>
                <div
                  className="field-icon-container"
                  onClick={() => handleRemoveField(index)}
                >
                  <Cross className="field-icon" />
                </div>
              </div>
            ))}
            <button
              type="button"
              className="more-btn1"
              onClick={() => handleAddField()}
            >
              + Add Field
            </button>
          </div> */}
          <div className="form-field3">
            <button className="submit-btn5" onClick={activateLeadForm}>
              {" "}
              Activate{" "}
            </button>
          </div>
        </div>
      </div>
      <Notification
        message={"You've successfully activated lead form"}
        show={showNotification}
        setShow={setShowNotification}
      />
    </section>
  );
};

export default LeadForm;
