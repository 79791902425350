import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";
import Notification from "../components/notification";

const Website = ({ card = {}, website = {}, reloadCard = () => {} }) => {
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (website.url) {
      setUrl(website.url);
    }
  }, [website]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!url) {
      setError("Please enter URL.");
      return;
    } else {
      setError("");
      if (website?.url) {
        axiosInstance
          .put(`${routes.createWebsite}/${website.id}`, {
            card: card.id,
            url: url,
          })
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              reloadCard(card.id, "/website/", false);
              setShowNotification(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data?.url) {
              setError(error.response.data.url[0]);
            } else {
              setError("Something went wrong");
            }
          });
      } else {
        axiosInstance
          .post(routes.createWebsite, {
            card: card.id,
            url: url,
          })
          .then((response) => {
            if (
              response.status &&
              response.status >= 200 &&
              response.status < 300
            ) {
              reloadCard(card.id, "/website/", false);
              setShowNotification(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data?.url) {
              setError(error.response.data.url[0]);
            } else {
              setError("Something went wrong");
            }
          });
      }
    }
  };

  return (
    <section className="page-content">
      <div className="custom-form2 custom-margin2">
        <div className="custom-form2-head text-center">
          <h3 className="col-black"> Website </h3>
        </div>
        <div className="custom-form2-content">
          <form onSubmit={handleSubmit}>
            <div className="form-field3">
              <h6 className="col-black"> Enter URL </h6>
              {error && <p className="error-message">{error}</p>}
              <input
                type="text"
                placeholder="URL"
                name=""
                className="field-style4"
                value={url || ""}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="form-field3">
              <button className="submit-btn5"> Activate </button>
            </div>
          </form>
        </div>
      </div>
      <Notification
        message={"You've successfully activated website"}
        show={showNotification}
        setShow={setShowNotification}
      />
    </section>
  );
};

export default Website;
