import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Phone from "../assets/images/phone.png";
import CellPhone from "../assets/images/cell_phone.png";
import Email from "../assets/images/email.png";
import Website from "../assets/images/website.png";
import Office from "../assets/images/office.png";
import Icon from "../assets/images/icon.svg";
import Close from "../assets/images/close.svg";
import { useNavigate, useParams } from "react-router-dom";
import { defaultColors, routes } from "../constants";
import axios from "axios";
import Headbox from "../components/headbox";
import PhoneInput from "react-phone-number-input";
import isMobilePhone from "validator/lib/isMobilePhone";

const PreviewProfile = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [focused, setFocused] = useState("");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!params.id) {
      navigate("/login/");
    } else {
      axios
        .get(routes.cardDetails(params.id))
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setProfile(response.data.contact_profile);
            setLoading(false);
          }
        })
        .catch((error) => {});
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBackendError("");
    if (!isFormValid()) {
      return;
    } else {
      axios
        .post(routes.exchangeContact, {
          name,
          email,
          number: phone,
          company,
          card: params.id,
        })
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            handleClose();
            setName("");
            setCompany("");
            setPhone("");
            setEmail("");
          }
        })
        .catch((err) => {
          setBackendError("Something went wrong");
        });
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!name) {
      errs.name = "Full name is required.";
    } else {
      delete errs.name;
    }
    if (!email) {
      errs.email = "Email format is wrong.";
    } else {
      delete errs.email;
    }
    if (!phone) {
      errs.phone = "Phone number is required.";
    } else if (!isMobilePhone(phone + "", "any", { strictMode: true })) {
      errs.phone = "Invalid number.";
    } else {
      delete errs.phone;
    }

    setErrors(errs);

    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="page preview-profile">
      {!loading && (
        <React.Fragment>
          <div id="main">
            <div className="container">
              <Headbox
                logo={profile?.logo}
                backgroundColor={profile?.background_color}
              />
              <div
                className="bottom-head"
                style={{
                  backgroundColor:
                    profile?.text_color || defaultColors?.cardColor,
                }}
              >
                {profile?.profile_image && (
                  <div className="col-6">
                    <img
                      src={profile.profile_image}
                      alt=""
                      className="bottom-head-image"
                    />
                  </div>
                )}
                <div className="col-6 text-start">
                  <h1>{profile.name}</h1>
                  <p>{profile.job_title}</p>
                </div>
              </div>

              <div className="exchange-contact">
                <div className="col-6">
                  <a
                    href={routes.downloadContact(params.id)}
                    target="_self"
                    className="download-contact-btn"
                  >
                    Save Contact
                  </a>
                </div>
                <div className="col-6">
                  <p
                    onClick={() => setShow(true)}
                    style={{
                      backgroundColor:
                        profile?.button_color || defaultColors?.buttonColor,
                    }}
                  >
                    Exchange Contact
                  </p>
                </div>
              </div>
              {profile?.bio && (
                <div className="preview-profile-about">
                  <h3 className="preview-profile-subtitle">About</h3>
                  <p className="preview-profile-about-paragraph">
                    {profile.bio}
                  </p>
                </div>
              )}
              {profile?.social_network.length > 0 && (
                <div className="social-box">
                  <h3 className="preview-profile-subtitle">Social network</h3>
                  <ul>
                    {profile.social_network.map((item, index) => (
                      <li key={index}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img src={item.social_media_url} alt="" />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="contact-info">
                <h3 className="preview-profile-subtitle">Contact info</h3>

                <ul>
                  {profile.number.map((item, index) => (
                    <li key={index}>
                      <div className="icon">
                        <img
                          src={item.phone_type === "cell" ? CellPhone : Phone}
                          alt="phone"
                          width="20"
                        />
                      </div>
                      <a
                        href={`tel:${item.number}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{item.number}</span>
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="var(--chakra-colors-brand-gray-300)"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            color: "var(--chakra-colors-brand-gray-300)",
                            minHeight: "24px",
                            minWidth: "24px",
                          }}
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </a>
                    </li>
                  ))}
                  {profile.email.map((item, index) => (
                    <li key={index}>
                      <div className="icon">
                        <img src={Email} alt="email" width="20" />
                      </div>
                      <a href={`mailto:${item.email}`} target="_self">
                        <span>{item.email}</span>
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="var(--chakra-colors-brand-gray-300)"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            color: "var(--chakra-colors-brand-gray-300)",
                            minHeight: "24px",
                            minWidth: "24px",
                          }}
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </a>
                    </li>
                  ))}

                  {profile.website.map((item, index) => (
                    <li key={index}>
                      <div className="icon">
                        <img src={Website} alt="website" width="20" />
                      </div>
                      <a href={item.website} target="_blank" rel="noreferrer">
                        <span>{item.website}</span>
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="var(--chakra-colors-brand-gray-300)"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            color: "var(--chakra-colors-brand-gray-300)",
                            minHeight: "24px",
                            minWidth: "24px",
                          }}
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </a>
                    </li>
                  ))}

                  {profile.address && (
                    <li>
                      <div className="icon">
                        <img src={Office} alt="address" width="20" />
                      </div>
                      <a
                        href={profile.google_maps || "#"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{profile.address}</span>
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="var(--chakra-colors-brand-gray-300)"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            color: "var(--chakra-colors-brand-gray-300)",
                            minHeight: "24px",
                            minWidth: "24px",
                          }}
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {profile?.logo && (
                <div className="powered-by">
                  <p>
                    Powered by{" "}
                    <a href="https://www.linkcardeg.com/" target="_blank">
                      Link
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            className="contact_rob modal"
            animation={false}
          >
            <header>
              <h3>
                <img src={Icon} alt="" width="24" />
                Share your contact with {profile.name}
              </h3>
            </header>
            <div className="close" onClick={() => setShow(false)}>
              <img src={Close} alt="" width="14" />
            </div>
            <Modal.Body>
              {backendError && <p className="error-message">{backendError}</p>}
              <form onSubmit={handleSubmit}>
                <div
                  className={
                    "field-box" +
                    (errors.name ? " field-error" : "") +
                    (focused === "name" ? " focus-in" : "")
                  }
                >
                  <div className="field-label">
                    <label
                      htmlFor="name"
                      style={{ top: focused === "name" || name ? "0" : "27px" }}
                    >
                      Full Name
                    </label>
                  </div>
                  <div className="field-name">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-field"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onFocus={() => setFocused("name")}
                      onBlur={() => setFocused("")}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>
                </div>

                <div
                  className={
                    "field-box" +
                    (errors.email ? " field-error" : "") +
                    (focused === "email" ? " focus-in" : "")
                  }
                >
                  <div className="field-label">
                    <label
                      htmlFor="email"
                      style={{
                        top: focused === "email" || email ? "0" : "27px",
                      }}
                    >
                      Email
                    </label>
                  </div>
                  <div className="field-name">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-field"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setFocused("email")}
                      onBlur={() => setFocused("")}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                </div>

                <div
                  className={
                    "field-box" + (focused === "company" ? " focus-in" : "")
                  }
                >
                  <div className="field-label">
                    <label
                      htmlFor="company"
                      style={{
                        top: focused === "company" || company ? "0" : "27px",
                      }}
                    >
                      Company
                    </label>
                  </div>
                  <div className="field-name">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      className="form-field"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      onFocus={() => setFocused("company")}
                      onBlur={() => setFocused("")}
                    />
                  </div>
                </div>

                <div
                  className={
                    "field-box" +
                    (errors.phone ? " field-error" : "") +
                    (focused === "phone" ? " focus-in" : "")
                  }
                >
                  <div className="field-label phone-label">
                    <label
                      htmlFor="phone"
                      style={{
                        top: focused === "phone" || phone ? "0" : "27px",
                        left: focused === "phone" || phone ? "0" : "42px",
                      }}
                    >
                      Phone number
                    </label>
                  </div>
                  <div className="field-name">
                    <PhoneInput
                      name="phone"
                      id="phone"
                      value={phone}
                      international={true}
                      onChange={(value) => {
                        setPhone(value);
                        if (
                          isMobilePhone(value + "", "any", {
                            strictMode: true,
                          })
                        ) {
                          let errs = { ...errors };
                          delete errs.phone;
                          setErrors(errs);
                        }
                      }}
                      onFocus={() => setFocused("phone")}
                      onBlur={() => setFocused("")}
                      className="form-field"
                    />
                    {errors.phone && <p className="error">{errors.phone}</p>}
                  </div>
                </div>

                <div className="submit-btn">
                  <input type="submit" value="Exchange Contact" />
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default PreviewProfile;
