import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./../assets/images/logo-dark.png";
import { ReactComponent as Celebrate } from "./../assets/images/celebrate.svg";

const Welcome = ({ user = {}, userLoading = false }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoading && Object.keys(user).length == 0) {
      navigate("/select-card/");
    }
  }, [user, userLoading]);

  return (
    <div className="welcome-page">
      <div className="logo1">
        <img src={Logo} />
      </div>
      <div className="welcome-page__body">
        <Celebrate className="welcome-page__icon" />
        <h1 className="welcome-page__title">Welcome, {user?.name}!</h1>
        <p className="welcome-page__paragraph">
          You have successfully activated your Link Card, now it's time to set
          it up. You will now enter your contact details and preview how people
          will view them. Take your time with this step because editing your
          contact will cost a fee in the future
        </p>
        <button
          className="submit-btn5"
          type="submit"
          onClick={() => navigate("/select-card/")}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Welcome;
