import React from "react";
import StatsItem from "./statsItem";

const StatsCards = ({ loading, stats }) => {
  return (
    <div className="d-flex">
      <div className="me-3">
        <StatsItem label={"Taps"} value={loading ? "-" : stats?.taps} />
      </div>
      <div className="me-3">
        <StatsItem label={"Leads"} value={loading ? "-" : stats?.leads} />
      </div>
      <div className="me-3">
        <StatsItem label={"Saves"} value={loading ? "-" : stats?.saves} />
      </div>
    </div>
  );
};

export default StatsCards;
