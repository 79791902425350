import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { routes } from "../constants";
import Logo from "../assets/images/logo-white.png";

const Register = ({ handleRegister = () => {} }) => {
  const params = useParams();

  const [id, setId] = useState("");
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState("");

  useEffect(() => {
    if (!params.id) {
      navigate("/login/");
    } else {
      setId(params.id);
    }
  }, []);

  const handleSubmit = (e) => {
    setBackendError("");
    e.preventDefault();
    if (isFormValid()) {
      axios
        .post(routes.register(id), {
          name,
          email,
          password,
        })
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            handleRegister({
              name: response.data.name,
              email: response.data.email,
              subscription: response.data.subscription,
              id: response.data.id,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 404
          ) {
            setBackendError("Card not found or already in use");
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 400 &&
            error.response.data.email
          ) {
            setBackendError("Email already in use");
          } else {
            setBackendError("Something went wrong");
          }
        });
    } else {
      return;
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!name) {
      errs.name = "Please enter your name.";
    } else {
      delete errs.name;
    }
    if (!email) {
      errs.email = "Please enter your email.";
    } else {
      delete errs.email;
    }
    if (!password || password.length < 8) {
      errs.password = "Password must be at least 8 characters.";
    } else {
      delete errs.password;
    }
    if (!confirmPassword) {
      errs.confirmPassword = "Please confirm your password.";
    } else if (confirmPassword !== password && password) {
      errs.confirmPassword = "Passwords don't match.";
    } else {
      delete errs.confirmPassword;
    }
    setErrors(errs);
    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const validateOnChange = (field, value) => {
    let errs = { ...errors };
    switch (field) {
      case "name":
        if (!value) {
          errs.name = "Please enter your name.";
        } else {
          delete errs.name;
        }
        break;
      case "email":
        if (!value) {
          errs.email = "Please enter your email.";
        } else {
          delete errs.email;
        }
        break;
      case "password":
        if (!value || value.length < 8) {
          errs.password = "Password must be at least 8 characters.";
        } else {
          delete errs.password;
        }
        break;
      case "confirmPassword":
        if (!value) {
          errs.confirmPassword = "Please confirm your password.";
        } else if (password && value !== password) {
          errs.confirmPassword = "Passwords don't match.";
        } else {
          delete errs.confirmPassword;
        }
        break;
      default:
        break;
    }
    setErrors(errs);
  };

  return (
    <section className="registration-forms-page">
      <div className="logo1 m-b-20">
        <img src={Logo} />
      </div>
      <div className="container custom-container1">
        <div className="sec-head1">
          <h3 className="col-white">
            Lets Create Your <br /> Account
          </h3>
        </div>
        {backendError && (
          <p className="error-message" style={{ marginBottom: "10px" }}>
            {backendError}
          </p>
        )}
        <div className="form-1">
          <form onSubmit={handleSubmit}>
            <div className="form-field1 registration-field">
              <input
                type="text"
                placeholder="Name"
                name="name"
                className={
                  "field-style1" + (errors.name ? " form-field-error" : "")
                }
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  validateOnChange(e.target.name, e.target.value);
                }}
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-field1 registration-field">
              <input
                type="email"
                placeholder="Email"
                name="email"
                className={
                  "field-style1" + (errors.email ? " form-field-error" : "")
                }
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateOnChange(e.target.name, e.target.value);
                }}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-field1 registration-field">
              <input
                type="password"
                placeholder="Password"
                name="password"
                className={
                  "field-style1" + (errors.password ? " form-field-error" : "")
                }
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validateOnChange(e.target.name, e.target.value);
                }}
              />
              {errors.password && (
                <p className="error-message">{errors.password}</p>
              )}
            </div>
            <div className="form-field1 registration-field">
              <input
                type="password"
                placeholder="Confirm password"
                name="confirmPassword"
                className={
                  "field-style1" +
                  (errors.confirmPassword ? " form-field-error" : "")
                }
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  validateOnChange(e.target.name, e.target.value);
                }}
              />
              {errors.confirmPassword && (
                <p className="error-message">{errors.confirmPassword}</p>
              )}
            </div>

            <div className="block-element m-t-20">
              <button className="submit-btn5"> SIGN UP </button>
            </div>

            <div className="get-card-now block-element text-center m-t-15">
              <p>
                Already have an account?{" "}
                <Link to={`/login/${id}`} className="col-orange">
                  {" "}
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Register;
