const baseUrl = "https://linkcardeg.me/api";
const authUrl = `${baseUrl}/authentication/token`;
const blackListUrl = `${baseUrl}/authentication/blacklist/`;
const cardUrl = `${baseUrl}/card`;
const lookups = `${baseUrl}/lookups`;

export const routes = {
  base: baseUrl,

  login: `${authUrl}/obtain/`,
  refresh: `${authUrl}/refresh/`,
  register: (id) => `${cardUrl}/${id}/register`,
  contactProfile: (id) => `${cardUrl}/contact-profile/${id}`,
  createProfile: `${cardUrl}/contact-profile`,
  deleteContactProfileImage: (id) =>
    `${cardUrl}/contact-profile/${id}/remove-photo`,
  cardList: `${cardUrl}/`,
  cardDetails: (id) => `${cardUrl}/${id}/details`,
  createWebsite: `${cardUrl}/website`,
  socialList: `${lookups}/social-media`,
  socialCreate: `${cardUrl}/social-network`,
  logout: `${blackListUrl}`,

  exchangeContact: `${cardUrl}/exchange-contact`,
  downloadContact: (id) => `${cardUrl}/${id}/download`,
  getCardStatistics: (id) => `${cardUrl}/${id}/stats`,
  getStatistics: `${cardUrl}/statistics/`,

  exchangedContacts: (id) => `${cardUrl}/${id}/exchange-contact`,
  allExchangedContacts: `${cardUrl}/exchange-contact`,
  downloadExchangedContact: (id) => `${cardUrl}/exchange-contact/${id}`,
  downloadCSV: (id) => `${cardUrl}/${id}/exchange-contact/download`,
  downloadCSVAll: `${cardUrl}/exchange-contact/download`,
  deleteContact: (id) => `${cardUrl}/exchange-contact/${id}`,

  activateLeadForm: `${cardUrl}/lead-form/activate/`,

  forgotPassword: `${baseUrl}/authentication/reset_password`,
  resetPassword: `${baseUrl}/authentication/reset_password/confirm`,

  editCard: (id) => `${baseUrl}/card/${id}`,

  me: `${baseUrl}/authentication/profile`,
  getSubscriptions: `${baseUrl}/payments/subscription-plans/`,
  createSubscription: `${baseUrl}/payments/subscriptions/`,
  changePassword: `${baseUrl}/authentication/change-password`,
};

export const defaultColors = {
  backgroundColor: "#222222",
  cardColor: "#010101",
  buttonColor: "#ff643a",
};
