import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { routes } from "../constants";
import Notification from "../components/notification";
import Headbox from "../components/headbox";
import PhoneInput from "react-phone-number-input";
import isMobilePhone from "validator/lib/isMobilePhone";

const LeadFormPage = () => {
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [focused, setFocused] = useState("");
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [profile, setProfile] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.id) {
      navigate("/login/");
    } else {
      axios
        .get(routes.cardDetails(params.id))
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setLoading(false);
            setProfile(response.data);
          }
        })
        .catch((error) => {});
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBackendError("");
    if (isFormValid()) {
      axios
        .post(routes.exchangeContact, {
          name,
          email,
          number: phone,
          company,
          card: params.id,
        })
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setShowNotification(true);
          }
        })
        .catch((err) => {
          setBackendError("Something went wrong");
        });
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!name) {
      errs.name = "Full name is required.";
    } else {
      delete errs.name;
    }
    if (!email) {
      errs.email = "Email format is wrong.";
    } else {
      delete errs.email;
    }
    if (!phone) {
      errs.phone = "Phone number is required.";
    } else if (!isMobilePhone(phone + "", "any", { strictMode: true })) {
      errs.phone = "Invalid number.";
    } else {
      delete errs.phone;
    }

    setErrors(errs);

    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="page lead-form-page">
      {!loading && (
        <div className="container">
          <div id="main">
            <Headbox />
            {backendError && <p className="error-message">{backendError}</p>}
            {profile?.contact_profile?.name && (
              <h3 className="leadform-title">
                Share your contact with {profile?.contact_profile?.name}
              </h3>
            )}
            <form onSubmit={handleSubmit}>
              <div
                className={
                  "field-box" +
                  (errors.name ? " field-error" : "") +
                  (focused === "name" ? " focus-in" : "")
                }
              >
                <div className="field-label">
                  <label
                    htmlFor="name"
                    style={{ top: focused === "name" || name ? "0" : "27px" }}
                  >
                    Full Name
                  </label>
                </div>
                <div className="field-name">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-field"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onFocus={() => setFocused("name")}
                    onBlur={() => setFocused("")}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
              </div>

              <div
                className={
                  "field-box" +
                  (errors.email ? " field-error" : "") +
                  (focused === "email" ? " focus-in" : "")
                }
              >
                <div className="field-label">
                  <label
                    htmlFor="email"
                    style={{ top: focused === "email" || email ? "0" : "27px" }}
                  >
                    Email
                  </label>
                </div>
                <div className="field-name">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setFocused("email")}
                    onBlur={() => setFocused("")}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>

              <div
                className={
                  "field-box" + (focused === "company" ? " focus-in" : "")
                }
              >
                <div className="field-label">
                  <label
                    htmlFor="company"
                    style={{
                      top: focused === "company" || company ? "0" : "27px",
                    }}
                  >
                    Company
                  </label>
                </div>
                <div className="field-name">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="form-field"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    onFocus={() => setFocused("company")}
                    onBlur={() => setFocused("")}
                  />
                </div>
              </div>

              <div
                className={
                  "field-box" +
                  (errors.phone ? " field-error" : "") +
                  (focused === "phone" ? " focus-in" : "")
                }
              >
                <div className="field-label phone-label">
                  <label
                    htmlFor="phone"
                    style={{
                      top: focused === "phone" || phone ? "0" : "27px",
                      left: focused === "phone" || phone ? "0" : "42px",
                    }}
                  >
                    Phone number
                  </label>
                </div>
                <div className="field-name">
                  <PhoneInput
                    name="phone"
                    id="phone"
                    value={phone}
                    international={true}
                    onChange={(value) => {
                      setPhone(value);
                      if (
                        isMobilePhone(value + "", "any", {
                          strictMode: true,
                        })
                      ) {
                        let errs = { ...errors };
                        delete errs.phone;
                        setErrors(errs);
                      }
                    }}
                    onFocus={() => setFocused("phone")}
                    onBlur={() => setFocused("")}
                    className="form-field"
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
              </div>

              <div className="submit-btn">
                <input type="submit" value="Exchange Contact" />
              </div>
            </form>
          </div>
        </div>
      )}
      <Notification
        message={"You've successfully sent this card holder your details"}
        show={showNotification}
        setShow={setShowNotification}
      />
    </div>
  );
};

export default LeadFormPage;
