import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";
import StatsCards from "../components/statsCards";

const SavedContacts = ({ card = {} }) => {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [confirmDelete, setConfirmDelete] = useState({});
  const [cardOptions, setCardOptions] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});

  useEffect(() => {
    if (card.id) {
      fetchAllCards();
    }
  }, [card]);

  useEffect(() => {
    setStats({})
    if (search === "" && selectedCard?.value) {
      fetchAllContacts(selectedCard.value);
    }
    if (selectedCard?.value) {
      getCardStats();
    }
  }, [search, selectedCard]);

  const getCardStats = () => {
    setLoading(true);
    if (selectedCard?.value === "all") {
      axiosInstance
        .get(routes.getStatistics)
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setStats((prev) => ({
              ...prev,
              leads: response?.data?.length,
              taps: response?.data?.taps,
              saves: response?.data?.saves,
            }));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      axiosInstance
        .get(routes.getCardStatistics(selectedCard?.value))
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setStats((prev) => ({
              ...prev,
              taps: response?.data?.cards?.[0]?.value,
              saves: response?.data?.cards?.[2]?.value,
            }));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const fetchAllCards = () => {
    axiosInstance
      .get(routes.cardList)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let results = [{ label: "All", value: "all" }];
          for (let i = 0; i < response.data.length; i++) {
            results.push({
              label: response?.data?.[i]?.card_name,
              value: response?.data[i]?.id,
            });
          }
          let current = results.filter((item, index) => {
            if (item.value === card.id) {
              return { label: item?.card_name, value: item.id };
            }
          });
          setCardOptions(results);
          setSelectedCard(current[0]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchAllContacts = (id = null) => {
    setLoading(true);
    let url = "";
    if (id === "all") {
      url = routes.allExchangedContacts;
    } else {
      url = routes.exchangedContacts(
        id ? id : selectedCard?.value ? selectedCard.value : card.id
      );
    }
    axiosInstance
      .get(url)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setContacts(response.data);
          setStats((prev) => ({
            ...prev,
            leads: response?.data?.length,
          }));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadContact = (id, name) => {
    axiosInstance.get(routes.downloadExchangedContact(id)).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".vcf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const searchContacts = (e) => {
    e.preventDefault();
    let url = "";
    if (selectedCard?.value === "all") {
      url = `${routes.allExchangedContacts}?search=` + search;
    } else {
      url =
        `${routes.exchangedContacts(
          selectedCard?.value ? selectedCard.value : card?.id
        )}?search=` + search;
    }
    axiosInstance.get(url).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setContacts(response.data);
      }
    });
  };

  const showDeleteConfirmation = (id, name, index) => {
    setConfirmDelete({ name, id, index });
  };

  const deleteContact = (id, index) => {
    setConfirmDelete({});
    let contactsCopy = [...contacts];

    axiosInstance
      .delete(routes.deleteContact(id))
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          contactsCopy.splice(index, 1);
          setContacts(contactsCopy);
        }
      })
      .catch((err) => {});
  };

  const handleCardChange = (e) => {
    let allCards = [...cardOptions];
    let selected = allCards.filter((item) => item.value === e.target.value);
    setSelectedCard(selected[0]);
    fetchAllContacts(e.target.value);
  };

  const downloadCSV = () => {
    let url = "";
    if (selectedCard?.value === "all") {
      url = routes.downloadCSVAll;
    } else {
      url = routes.downloadCSV(selectedCard?.value);
    }
    axiosInstance.get(url).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", selectedCard?.label + ".csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return (
    <section className="page-content">
      <React.Fragment>
        <div className="page-header custom-margin1">
          <h3 className="col-black"> Exchanged Contacts </h3>
        </div>
        <div className="saved-contacts-actions">
          <select
            className="card-selector"
            value={selectedCard.value}
            onChange={(e) => handleCardChange(e)}
            disabled={loading}
          >
            {cardOptions.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
          </select>
          <button
            type="button"
            className="export-btn"
            onClick={() => downloadCSV()}
            disabled={loading}
          >
            Export CSV
          </button>
        </div>
        <div className="block-element">
          <div className="search-bar">
            <form onSubmit={(e) => searchContacts(e)}>
              <input
                type="text"
                placeholder="Search"
                name=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={loading}
              />
              <button type="submit" disabled={loading}>
                <i className="fa fa-search"> </i>{" "}
              </button>
            </form>
          </div>
        </div>

        <div className="block-element m-t-30 m-b-30">
          <StatsCards loading={loading} stats={stats} />
        </div>

        {!loading &&
          contacts.map((contact, index) => (
            <div className="block-element" key={index}>
              <div className="saved-box5">
                <div>
                  <h5> {contact.name} </h5>
                  <h6> {contact.company || ""} </h6>
                  <h6>
                    Exchanged: {new Date(contact?.created_at).toDateString()}
                  </h6>
                </div>
                <div>
                  <span> {contact.number} </span>
                  <span> {contact.email}</span>
                </div>
                <div>
                  <button
                    onClick={() => downloadContact(contact.id, contact.name)}
                    className="save-btn6 contacts-save-btn"
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
                <span
                  className="contact-delete-icon"
                  onClick={() =>
                    showDeleteConfirmation(contact.id, contact.name, index)
                  }
                >
                  <i className="fa fa-trash"> </i>{" "}
                </span>
              </div>
            </div>
          ))}
        <Modal
          show={Object.keys(confirmDelete).length !== 0}
          onHide={() => setConfirmDelete({})}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <ModalBody>
            <h4>Are you sure you want to delete {confirmDelete?.name}?</h4>
          </ModalBody>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() =>
                deleteContact(confirmDelete.id, confirmDelete.index)
              }
            >
              Delete
            </Button>
            <Button variant="light" onClick={() => setConfirmDelete({})}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </section>
  );
};

export default SavedContacts;
