import React from "react";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";

const Notification = ({
  title = "LINK",
  message = "",
  show = false,
  setShow = () => {},
}) => {
  return (
    <ToastContainer position="top-end" className="custom-toast">
      <Toast
        animation={true}
        autohide
        delay={4000}
        onClose={() => setShow(false)}
        show={show}
      >
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Notification;
