import React from "react";

const StatsItem = ({ label, value }) => {
  return (
    <div className="stats-item p-3 border rounded-3">
      <p className="stats-item__label fs-6 fw-bold">{label}</p>
      <p className="stats-item__value fs-4 fw-bold">{value}</p>
    </div>
  );
};

export default StatsItem;
