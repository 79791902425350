import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./styles.css";

function DragDrop({ value, onChange, label, id, fileTypes }) {
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    // create the preview
    let objectUrl;
    if (value) {
      if (value instanceof File) {
        objectUrl = URL.createObjectURL(value);
        setPreview(objectUrl);
        setFileName(value?.name);
      } else {
        setPreview(value);
      }
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  return (
    <div className="d-flex flex-column h-100">
      <label htmlFor={id} className="file-upload__label">
        {label}
      </label>
      <FileUploader
        handleChange={(value) => onChange(value)}
        name={id}
        types={fileTypes}
        fileOrFiles={value}
        multiple={false}
        classes="file-upload"
      />
      <div className="d-flex align-items-center mt-2">
        {preview && <img src={preview} alt="" className="image-preview me-2" />}
        {fileName && <p>{fileName}</p>}
      </div>
    </div>
  );
}

export default DragDrop;
