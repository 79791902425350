import React, { useState } from "react";
import { useRef } from "react";
import axiosInstance from "../axiosApi";
import { routes } from "../constants";

const Settings = () => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);

  const pwChangeTimeout = useRef(null);

  const handleSubmit = () => {
    if (areFormsValid()) {
      axiosInstance
        .post(routes.changePassword, {
          password: oldPass,
          new_password: newPass,
        })
        .then((res) => {
          if (res?.status && res.status >= 200 && res.status < 300) {
            setSuccessMessage("Password successfully changed!");
            setNewPass("");
            setOldPass("");
            setConfPass("");
            if (pwChangeTimeout?.current) {
              clearTimeout(pwChangeTimeout?.current);
              pwChangeTimeout.current = setTimeout(() => {
                setSuccessMessage(null);
              }, 10000);
            } else {
              pwChangeTimeout.current = setTimeout(() => {
                setSuccessMessage(null);
              }, 10000);
            }
          }
        })
        .catch((err) => {
          if (
            err?.response?.status &&
            err?.response.status == 400 &&
            err?.response?.data["password"]
          ) {
            setErrors((current) => {
              return { ...current, backend: "Old Password is incorrect." };
            });
          } else if (
            err?.response?.status &&
            err?.response.status == 400 &&
            err?.response?.data["new_password"]
          ) {
            setErrors((current) => {
              return {
                ...current,
                backend: "New Password can't be the same as Old Password.",
              };
            });
          }
        });
    }
  };

  const areFormsValid = () => {
    let errs = { ...errors };
    delete errs.backend;
    if (!oldPass) {
      errs.oldPass = "Old password is required";
    } else {
      delete errs.oldPass;
    }
    if (!newPass || newPass.length < 8) {
      errs.newPass = "New password must be at least 8 characters";
    } else {
      delete errs.newPass;
    }
    if (!confPass) {
      errs.confPass = "Please confirm your password.";
    } else if (confPass !== newPass && newPass) {
      errs.confPass = "Passwords don't match.";
    } else {
      delete errs.confPass;
    }
    setErrors(errs);
    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section className="page-content settings">
      <h1 className="settings__title">Settings</h1>
      <div className="settings__tab">
        <h3 className="settings__tab-title">Security</h3>
        <div className="settings__tab-body">
          <h3 className="settings__tab-body-title">Change Password</h3>
          <form className="settings__change-password">
            {errors?.backend && (
              <p className="error-message">{errors.backend}</p>
            )}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            <div className="form-container">
              <div className="input-container">
                <label htmlFor="oldPassword">Old Password</label>
                <input
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="Enter your old password"
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
                {errors?.oldPass && (
                  <p className="input-error">{errors.oldPass}</p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="Enter your new password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                {errors?.newPass && (
                  <p className="input-error">{errors.newPass}</p>
                )}
              </div>
            </div>
            <div className="form-container">
              <div className="input-container">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Enter confirm password"
                  value={confPass}
                  onChange={(e) => setConfPass(e.target.value)}
                />
                {errors?.newPass && (
                  <p className="input-error">{errors.confPass}</p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <button type="button" onClick={handleSubmit} className="save-btn">
        Save
      </button>
    </section>
  );
};

export default Settings;
