import React, { useEffect, useRef } from "react";
import Logo from "../assets/images/logo.svg";
import { defaultColors } from "../constants";

const Headbox = ({ logo = "", backgroundColor = null }) => {
  const boxRef = useRef();

  useEffect(() => {
    var style = document.querySelector(".head-box")?.style;
    style?.setProperty("--head-box-bg-color", backgroundColor || defaultColors?.backgroundColor);
  }, [backgroundColor]);

  return (
    <div className="head-box" ref={boxRef}>
      <div className="head-box-logo-container">
        <img
          src={logo ? logo : Logo}
          alt=""
          width="92"
          className="head-box-logo"
        />
      </div>
      <div>
        <a
          href="https://www.linkcardeg.com/"
          className="btn btn-outline-secondary"
          target="_blank"
          rel="noreferrer"
        >
          Get Your Card
        </a>
      </div>
    </div>
  );
};

export default Headbox;
