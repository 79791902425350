import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo-white.png";
import { routes } from "../constants";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [backendErrors, setBackendErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.token) {
      navigate("/login/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBackendErrors("");
    setSuccess(false);
    if (isFormValid()) {
      setLoading(true);
      axios
        .post(routes.resetPassword, {
          password,
          token: params.token,
        })
        .then((response) => {
          if (
            response.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            setSuccess(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error?.response?.data?.token
          ) {
            setBackendErrors("This link is invalid or expired");
          } else {
            setBackendErrors("Something went wrong");
          }
        });
    } else {
      return;
    }
  };

  const isFormValid = () => {
    let errs = { ...errors };
    if (!password || password.length < 8) {
      errs.password = "Password must be at least 8 characters.";
    } else {
      delete errs.password;
    }
    if (!confirmPassword) {
      errs.confirmPassword = "Please enter password confirmation.";
    } else if (password && confirmPassword && password !== confirmPassword) {
      errs.confirmPassword = "Passwords don't match.";
    } else {
      delete errs.confirmPassword;
    }

    setErrors(errs);

    if (Object.keys(errs).length !== 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <section className="registration-forms-page">
      <div className="logo1 m-b-20">
        <img src={Logo} />
      </div>
      <div className="container custom-container1">
        <div className="sec-head1 m-b-30">
          <h3 className="col-white"> Set New Password </h3>
          <p className="sec-p1">
            Your New Password Must Be Different To Previously used password
          </p>
        </div>
        {backendErrors && <p className="error-message">{backendErrors}</p>}
        {success && (
          <div className="form-field-submit text-left m-b-20 reset-password-success">
            You've successfully reset your password.
            <Link to="/login/" className="reset-password-login">
              Login
            </Link>
          </div>
        )}
        {!success && (
          <div className="form-1">
            <form onSubmit={handleSubmit}>
              <div className="form-field1">
                <input
                  type="password"
                  placeholder="New Password"
                  className={
                    "field-style1" +
                    (errors.password ? " form-field-error" : "")
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <p className="error-message">{errors.password}</p>
                )}
              </div>
              <div className="form-field1">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className={
                    "field-style1" +
                    (errors.confirmPassword ? " form-field-error" : "")
                  }
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && (
                  <p className="error-message">{errors.confirmPassword}</p>
                )}
              </div>
              <p className="reset-password-hint">
                Must be at least 8 characters
              </p>
              <div className="block-element">
                <button className="submit-btn5" type="submit">
                  {loading ? "Loading..." : "Reset Password"}
                </button>
              </div>
            </form>
            <Link to="/login/" className="forgot-password-back">
              <span className="forgot-password-back-icon">&larr;</span> Back To
              Login
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
